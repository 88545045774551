












































































import { Toast,Notify } from "vant"
import Stroage from "@/util/Storage"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import AllCom from "@/components/ShopComponent/AllComponent.vue"
import Search from '@/components/ShareComponent/Search.vue'
import PullItemCom from '@/components/ShareComponent/PullSelItem.vue'
import FilterItem from "@/components/ShopComponent/PullSelView.vue"
import List from "@/components/ShareComponent/PullDownUp.vue"
import UpDownCom from '@/components/ShareComponent/ListBottom.vue'
import { ShopItemType } from '@/Type/index'
import { AllUpDataType } from '@/Api/Shop/interfaceType'
import { ShopStateType } from '@/Type/StoreType'
import { ShopStore } from '@/store/modules/Shop/Shop'
import { IndexStore } from '@/store/modules/Index'
import { AllShop as AllUpShop } from '@/Api/Shop/index'
import { Vue,Component,Watch,Ref } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface PullSelItemType<T>{
    title:T;
    order?:T;
}

interface AllShopType<T=string>{
    SelIcon:T;
    orderIcon:T;
    loadingShow:boolean
    userId:T|number;
    token:T;
    Scroll:number;
    PullSelList:Array<PullSelItemType<T>>
    PullItemIndex:number;
    ShopState:ShopStateType<string|number>
    SearchVal:T;
    InputShow:boolean
    SearchBool:boolean
    SelIndex:number;
    upDown:boolean;
    pullDown:boolean;
    stopUpDown:boolean;
    List:ShopItemType[];
    UpData:AllUpDataType;

    init():void;
    initData():void;
    handleToPath():void;
    handleShowClose():void;
    handleUpSerchBtn(e:Event):void;
    handleShowInput():void;
    handleChangeSelIndex(index:number):void;
    handlePullItem(data:any):void;
    handlePullDown(data:any):void;
    handleUpDown(data:any):void;
    handleUpData(bool?:boolean):void;
    handleClick():void;
    handleError(data:string):void;
}

@Component({ name:"AllShop",components:{ HeadTop,Search,PullItemCom,List,AllCom,UpDownCom,FilterItem } })
export default class AllShop extends ZoomPage implements AllShopType{
   loadingShow = true
   userId: string|number = ""
   token = ""
   Scroll = 0;
   orderIcon = require("$icon/Shop/OrderList.png");
   SelIcon = require("$icon/Shop/Filter.png");
   PullSelList:Array<PullSelItemType<string>> = [
       { title: "综合排序" },
       { title: "升序",order:"desc"},
       { title: "降序",order:"asc" },
   ];
   PullItemIndex = 0;
   ShopState: ShopStateType<string|number> = {
       state:0,
       title:"零食铺子",
       type:0
   }
   SearchVal = ""
   InputShow = false
   SearchBool = false
   SelIndex = 0;
   upDown = true
   pullDown = false;
   stopUpDown = false;
   List:Array<ShopItemType> = []
   UpData:AllUpDataType = {
       pageNo:1,
       pageSize:15,
       type:2
   }

    activated() {
       this.loadingShow = false
       this.userId = Stroage.GetData_ && Stroage.GetData_("userId")
       this.token = Stroage.GetData_ && Stroage.GetData_("token")
        let { type = "" } = this.$route.query
        if ( type && type != IndexStore.GetShopState.type ){
            IndexStore.SetShopState({ ...IndexStore.GetShopState,type: type as string })
        }
       this.ShopState = IndexStore.GetShopState
       this.initData()
       this.PullSelList = [ { title: "综合排序" }, { title: "升序",order:"desc"}, { title: "降序",order:"asc" }, ]
   }

   init(){
       switch( Number( this.ShopState.state ) ){
           case 1:
               this.Scroll = ShopStore.GetShopScroll.SnackScroll
               break;
           case 2:
               this.Scroll = ShopStore.GetShopScroll.EatScroll
               break;
           case 3:
               this.Scroll = ShopStore.GetShopScroll.ShopScroll
               break;
           case 4:
               this.Scroll = ShopStore.GetShopScroll.FreshScroll
               break;
       }
   }

   initData(){
       if( ShopStore.GetShopUpData.type == IndexStore.GetShopState.type ){
           if( ShopStore.GetShopUpData.name ){//判断是否是搜索记录值
               this.handleUpData()
               return;
           }
           this.UpData = {
               ...ShopStore.GetShopUpData,
               type:IndexStore.GetShopState.type as string
           }
           if( ShopStore.GetShopUpData.order ){
               switch( ShopStore.GetShopUpData.order ){
                   case "desc":
                       this.PullItemIndex = 1
                       break;
                   case "asc":
                       this.PullItemIndex = 2
                       break;
               }
           }
           if( ShopStore.GetAllShopList.length ){
               this.loadingShow = false
               this.upDown = false
               this.List = ShopStore.GetAllShopList
               this.init()
           }else{
               this.handleUpData()
           }
       }else{
           this.UpData = {
               ...this.UpData,
               pageNo:1,
               type:IndexStore.GetShopState.type as string
           }
           this.List = []
           this.upDown = true
           this.handleUpData(true)
       }
   }

   handleToPath(){
       this.$router.push({
           path:"/orderList"
       })
   }

   handleShowClose(): void {
       let reg = / /g
       this.SearchVal = this.SearchVal.replace(reg,"")
       if( this.SearchVal.length <= 0 ){ this.InputShow = false }
   }

   handleUpSerchBtn(e: Event): void {
       let reg = / /g
       this.SearchVal = this.SearchVal.replace(reg,"")
       if( this.SearchVal.length ){
           this.loadingShow = true
           this.UpData = {
               name:this.SearchVal,
               pageNo:1,
               pageSize:this.UpData.pageSize,
               type:this.UpData.type
           }
           this.handleUpData( true )
           this.SearchBool = true
       }
   }

   handleShowInput(){
        this.InputShow = true
        this.input.focus()
    }

   handleChangeSelIndex(index:number){
       if( index == 3 ){
           Toast({
               message:"暂未开放功能",
               icon:"none",
               className:"ToastClassName"
           })
           return
       }
       if( this.SelIndex !== index ){
           this.SelIndex = index
           this.UpData.pageNo = 1;
           switch( index ){
               case 1:
                   this.UpData.sort = "monthly_sale"
                   break;
               case 2:
                   this.UpData.sort = "score"
                   break;
           }
           // this.loadingShow = true
           this.upDown = true
           this.stopUpDown = false
           this.handleUpData(true)
       }
   }

   handlePullItem(data:any){
       this.UpData.pageNo = 1
       if( data.data.order ){
           this.UpData.order = data.data.order
           this.PullItemIndex = data.index
       }else{
           delete this.UpData.order
           delete this.UpData.sort
       }
       this.upDown = true
       this.stopUpDown = false
       this.handleUpData(true)
   }

   handlePullDown(data: any): void {
       this.pullDown = false
       this.UpData.pageNo = 1
       AllUpShop({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.pullDown = true
           if( res.message.code === "200" ){
               if( res.data.storeList.length < this.UpData.pageSize ){
                   this.stopUpDown = true
               }else{
                   this.UpData.pageNo += 1
                   this.stopUpDown = false
               }
               this.List = res.data.storeList
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleUpDown(data: any): void {
       if( this.stopUpDown )return;
       this.upDown = true
       this.UpData.pageNo += 1
       this.handleUpData()
   }

   handleUpData(bool?:boolean){
       AllUpShop({
           userId:this.userId,
           token:this.token
       },this.UpData).then( (res:any)=>{
           this.loadingShow = false
           this.upDown = false
           if( res.message.code === "200" ){
               if( res.data.storeList.length < this.UpData.pageSize ){
                   this.stopUpDown = true
               }else{
                   this.UpData.pageNo += 1
               }
               if( bool ){
                   this.List = res.data.storeList
               }else{
                   this.List = this.List.concat( res.data.storeList )
               }
           }else{
               this.handleError( res.message.msg )
           }
       } )
   }

   handleClick(){
       let Num:number = IndexStore.GetScrollNum
       let data = { ...ShopStore.GetShopScroll }
       switch( Number( this.ShopState.type ) ){
           case 2:
               data.SnackScroll = Num
               break;
           case 0:
               data.EatScroll = Num
               break;
           case 1:
               data.ShopScroll = Num
               break;
           case 3:
               data.FreshScroll = Num
               break;
       }
       ShopStore.SetScrollType( data )
   }

   handleError( data:string ){
       let str = ""
       if ( data.length ){
           str = data
       }else{
           str = "网络繁忙..."
       }
       Notify({
           message: str,
           color: 'white',
           background: "rgba(0,0,0,.6)",
       });
   }

   @Watch("List")
   handleChangeList(newVal:Array<ShopItemType>){
       ShopStore.SetAllShopList( newVal )
   }

   @Ref("serch")
   readonly input!:HTMLElement;

   @Watch("SearchVal")
   handleChangeVal(newval:any){
       if( this.SearchBool ){
           if( newval.length <= 0 ) {
               this.SearchBool = false
               this.loadingShow = true
               this.UpData = {
                   ...this.UpData,
                   pageNo :1
               }
               delete this.UpData.sort
               delete this.UpData.order
               delete this.UpData.name
               this.handleUpData(true)
           }
       }
   }

   @Watch("UpData")
   handleChangeUpData(newVal:AllUpDataType){
       ShopStore.SetShopUpData( newVal )
   }
}
