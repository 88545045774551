



























import { Vue,Component,Prop,Emit, Watch } from "vue-property-decorator"

interface PullSelViewType<T=string>{
    NavList:Array<any>
    Show:boolean;
    handleSearchShop():void;
    handleClearShop():void;
    handleClearData():void;
    handlePullData():any;
    handleFilter(idx?:number):void;
    InitNavData():void;
}

@Component({ name:"PullSelView" })
export default class PullSelView extends Vue implements PullSelViewType{
    
    NavList:Array<any> = [
        "满减优惠","店铺红包","赠品优惠","免配送费","免餐盒费","面打包费",
    ]
    Show = false;

    mounted() {
       this.InitNavData()   
    }

    handleSearchShop(){
        this.FindData( this.handlePullData() )
    }

    handleClearShop(){
        this.handleClearData()
        this.ClearData()
    }

    handleClearData(){
        let { NavList } = this;
        let List:Array<{ active:boolean;value:string }> = []
        NavList.forEach((item,index)=>{
            item.active = false
            List.push( item )
        })
        this.NavList = List
    }

    handlePullData(){
        let { NavList } = this;
        let List:Array<{ active:boolean;value:string }> = []
        NavList.forEach((item,index)=>{
            if( item.active ){
                List.push(item)
            }
        })
        return List;
    }

    handleFilter(idx:number){
        let { NavList } = this;
        let List:Array<{ active:boolean;value:string }> = []
        NavList.forEach((item,index)=>{
            if( index == idx ){
               item.active = !item.active
            }
            List.push(item)
        })
        this.NavList = List
    }

    InitNavData(){
        let { NavList } = this
        let List:Array<{ active:boolean;value:string }> = []
        NavList.forEach((item,index)=>{
            List.push({
                active:false,
                value:item
            })
        })
        this.NavList = List
    }

    @Prop(String)
    title!:string

    get GetTitle(){
        return this.title
    }

    @Emit("FindData")
    FindData(data:any){
        return data
    }

    @Emit("ClearData")
    ClearData(){
        return true
    }

    @Watch("Show")
    handleChangeShow(newVal:boolean){
        if( newVal ){
            document.body.style.overflow = "hidden"
        }else{
            document.body.style.overflow = "auto"
        }
    }
}
