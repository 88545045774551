






















































































 import { Toast,Dialog } from 'vant'
 import { ShopStateType } from "@/Type/StoreType"
 import { ShopSucStore } from "@/store/modules/Shop/ShopSuc"
 import { IndexStore } from "@/store/modules/Index"
 import { ShopItemType,ShopCouponType } from "@/Type/index"
 import { Vue,Component,Prop,Emit,Watch } from "vue-property-decorator"

 interface AllComponentType<T=string>{
     DefaultIcon:T;
     NoticeIcon:T;
     
     QuikIcon:T;
     NormalIcon:T;
     HotIcon:T;
     status:number;
     CouponList:ShopCouponType[];
     ShopState:ShopStateType<T|number> ;
    
     initData():void;
     handleToPath():void;
     FilterTime(data:string):boolean;
     handleFilter(data:any):any;
 }

 @Component({ name:"AllComponent" })
 export default class AllComponent extends Vue implements AllComponentType{
    DefaultIcon = require("$icon/Shop/TestShop.png");
    NoticeIcon = require("$icon/Shop/StopNotice.png");
    QuikIcon = require("$icon/Shop/quik.png");
    NormalIcon = require("$icon/Shop/normal.png");
    HotIcon = require("$icon/Shop/hot.png");
    status = 0;
    CouponList:ShopCouponType[] = []
    ShopState: ShopStateType<string|number> = {
        state:1,
        title:"零食铺子",
        type:2
    };
    
    mounted() {
        this.ShopState = IndexStore.GetShopState
        if( this.GetData ){
            this.initData()   
        }
    }

    initData(){
        let reg = /速/g
        let infoRef = /正常/g
        let hotRef = /爆单/g
        if ( reg.test( String( this.GetData.leastStoreStatus ) ) ){
            this.status = 0
        }else if( infoRef.test( String( this.GetData.leastStoreStatus ) ) ){
            this.status = 1
        }else if( hotRef.test( String( this.GetData.leastStoreStatus ) ) ){
            this.status = 2
        }

        if ( Boolean(this.GetData.reducteList) && Array.isArray( this.GetData.reducteList ) ){
            let List:ShopCouponType[] = [];
            if ( this.GetData.reducteList.length > 2 ){
                for ( let i = 0; i < 2; i ++ ){
                    List.push(
                        this.GetData.reducteList[i]
                    )
                }
            }else{
                for ( let i = 0; i < this.GetData.reducteList.length; i ++ ){
                    List.push(
                        this.GetData.reducteList[i]
                    )
                }
            }
            this.CouponList = List
        }
    }

    handleToPath(){
        if( Number( this.GetData.status ) > 0 ){
            let str = ""
            switch( Number( this.GetData.status ) ){
                case 1:
                    str = "商铺已下架"
                    break;
                case 2:
                    Dialog.confirm({
                        title: '温馨提醒',
                        message: '店铺已歇业,是否调转到店铺详情？',
                        confirmButtonText:"确定",
                        cancelButtonText:"取消",
                        className:"HideClassName",
                        overlayClass:"MaskHideClassName"
                    }).then(res=>{
                        this.$router.push({
                            path:"/shopSuc",
                            query:{
                                id:this.GetData.id as string,
                                title:this.GetData.name as string
                            }
                        })
                    }).catch( cat=>cat )
                    return;
                case 3:
                    str = "商铺未通过审核"
                    break;
            }
            Toast({
                message:str,
                icon:"none",
                className:"ToastClassName"
            })
        }else{
            if( ShopSucStore.GetShopSuc.id != this.GetData.id ){
                ShopSucStore.SetOnShopList( [] )
                ShopSucStore.SetAppointShop( false )
            }
            this.$router.push({
                path:"/shopSuc",
                query:{
                    id:this.GetData.id as string,
                    title:this.GetData.name as string
                }
            })
            ShopSucStore.SetShopSuc( this.GetData )
            this.Click()
        }
    }

    FilterTime(data:string){
        let timeArr = data.split(",")
        if ( timeArr.length > 1 ){
            return false
        }else{
            let timeOne = timeArr[0].split("-")
            if ( timeOne.length > 1 ) {
                return false
            }else{
                return true
            }
        }
    }

    handleFilter( data:string ){
        let timeArr = data.split(",")
        if ( timeArr.length > 1 ){
            //  "10:00-14:00,16:00-20:00"
            let date = new Date()
            let shi = date.getHours()
            let fen = date.getMinutes()
            let SF = ( shi + "" + fen ) || 0;
            let timeOne = timeArr[0].split("-")
            let timeTwo = timeArr[1].split("-")
            // 第一
            // 之前
            let beforTime = timeOne[0].split(":")
            let before = ( beforTime[0] + "" + beforTime[1] ) || 0
            // 之后
            let afterTime = timeOne[1].split(":")
            let after = ( afterTime[0] + "" + afterTime[1] ) || 0
            // 第二个
            // 之前
            let beforTimeT = timeTwo[0].split(":")
            let beforeT = ( beforTimeT[0] + "" + beforTimeT[1] ) || 0
            // 之后
            let afterTimeT = timeTwo[1].split(":")
            let afterT = ( afterTimeT[0] + "" + afterTimeT[1] ) || 0
            if ( before < beforeT ){
                //"10:00-14:00,16:00-20:00"
                if ( SF > before ){
                    if( SF < after ){
                        return "持续接单中..."
                    }else{
                        if ( SF < beforeT ){
                            return `预定中${ timeTwo[0] }开始配送"`
                        }else{
                            if ( SF < afterT ){
                                return "持续接单中..."
                            }else{
                                return `预定中明天${ timeOne[0] }开始配送`
                            }
                        }
                    }
                }else{
                    return `预定中${ timeOne[0] }开始配送"`
                }
            }else{
                //16:00-20:00","10:00-14:00
            }
        }else{
            let timeOne = timeArr[0].split("-")
            if ( timeOne.length > 1 ) {
                let date = new Date()
                let shi = date.getHours()
                let fen = date.getMinutes()
                let SF = ( shi + "" + fen ) || 0;
                // 之前
                let beforTime = timeOne[0].split(":")
                let before = ( beforTime[0] + "" + beforTime[1] ) || 0
                // 之后
                let afterTime = timeOne[1].split(":")
                let after = ( afterTime[0] + "" + afterTime[1] ) || 0
                if ( SF >= before ){
                    if ( SF > after ){
                        return `预定中明天${ timeOne[0] }开始配送"`
                    }else{
                        return "持续接单中..."
                    }
                }else{
                    return `预定中${ timeOne[0] }开始配送"`
                }
            }else{
                return timeArr[0]
            }
        }
    }

   @Prop(Object)
   data!:ShopItemType;

   get GetData(){
       return this.data
   }

   @Watch("GetData")
   handleChangeGetData(newVal:any){
       this.initData()
   }

   @Emit("Click")
   Click(){
       return true
   }

   @Prop(Number)
   index!:number;

   get GetIndex(){
       return this.index
   }
}
